import React from "react";
import cx from "classnames";
import { useMsal } from "@azure/msal-react";
import { IonIcon } from "@ionic/react";
import { cashOutline } from "ionicons/icons";

import { useTuitionInfo } from "utils/hooks/wallet";
import { usePartnerDetails } from "utils/hooks/partner";
import { NxuAlert, NxuContentLoading } from "@nexford/nexford-ui-component-library";
import GetInTouch from "components/atom/get-in-touch";
import { PageContent } from "components/molecule/page-wrap/page-wrap";
import { useAuthContext } from "utils/context/Auth";
import { useLearner } from "utils/hooks/learners";
import { PaymentGroups } from "types/wallet";
import BillingInfo from "./billing-info";

import "./wallet.scss";

const WalletPage = () => {
  const { instance } = useMsal();
  const { userAccount } = useAuthContext();

  const { data: learnerData } = useLearner(!!userAccount, instance);

  const { data: tuitionData, isLoading: tuitionDataLoading, error: tuitionDataError } = useTuitionInfo(instance);
  const {
    data: partnerData,
    isLoading: partnerDataLoading,
    error: partnerDataError,
  } = usePartnerDetails(instance, learnerData?.partnerId);

  const hasPartner = Boolean(learnerData?.partnerId);
  const isPartnerHandlingPayment =
    hasPartner && tuitionData?.paymentGroup?.name === PaymentGroups.PartnershipLearnerGroup;

  const isLoading = tuitionDataLoading || partnerDataLoading;

  const errorMessage = tuitionDataError?.message || partnerDataError?.message;

  return (
    <PageContent className="wallet__wrapper">
      <div className={cx("wallet__title")}>
        <>
          <IonIcon size="large" icon={cashOutline}></IonIcon>
          <h1>My Wallet</h1>
        </>
      </div>

      {isLoading && <NxuContentLoading />}

      {!isLoading && (
        <div className="wallet__inner" data-testid="inner-page">
          {!!errorMessage && <NxuAlert message={errorMessage} />}

          {!!tuitionData && (
            <div className="wallet__payment">
              <BillingInfo
                programStatus={tuitionData.status}
                nextInvoiceDate={tuitionData.nextBillDate}
                paymentFrequency={tuitionData.paymentFrequency}
                invoices={tuitionData.invoices}
                balance={tuitionData.balance}
                totalAmountDue={tuitionData.totalAmountDue}
                hasOldInvoices={tuitionData.hasOldInvoices}
                paymentGroup={tuitionData.paymentGroup}
                isPartnerHandlingPayment={isPartnerHandlingPayment}
                partnerName={partnerData?.name}
                programName={learnerData?.programFriendlyName}
              />
            </div>
          )}
          {!!tuitionDataError && <GetInTouch />}
        </div>
      )}
    </PageContent>
  );
};

export default WalletPage;
