import { useMutation, useQuery } from "@tanstack/react-query";

import { IPublicClientApplication } from "@azure/msal-browser";
import {
  AggregateLearnerProfile,
  DocumentStatusList,
  DocumentStatusTypes,
  AepDocument,
  ProgramProgressSummary,
  PrivacySettingResponse,
  PrivacySettingPayload,
} from "types/learner";
import { apiGet, apiPost, apiPostFile, apiPut } from "./api-client";

export const learnerErrMsg = {
  fetchLearner:
    "Uh-oh! There was an error on getting your learner profile. Please attempt to logout and then login again. If the problem persists, contact support.",
  fetchPrivacySetting:
    "Uh-oh! There was an error on getting your privacy settings. Please refresh the page. If the problem persists, contact support.",
  updatePrivacySetting:
    "Uh-oh! There was an error on updating your privacy settings. Please refresh and try again or contact support.",
  fetchDocumentStatus:
    "Uh-oh! There was an error on getting your documents. Please refresh the page. If the problem persists, contact support.",
  uploadDocument:
    "Uh-oh! There was an error on uploading your document. Please refresh and try again or contact support.",
};

// Get aggregate learner profile
const fetchLearner = async (msalInstance: IPublicClientApplication): Promise<AggregateLearnerProfile> =>
  apiGet("/api/mynxuaggregator/self/profile", learnerErrMsg.fetchLearner, msalInstance);

const useLearner = (hasAccount: boolean, msalInstance: IPublicClientApplication) =>
  useQuery({
    queryKey: ["learner-profile"],
    queryFn: () => fetchLearner(msalInstance),
    throwOnError: false,
    enabled: hasAccount,
    retry: 3,
    staleTime: 600000,
  });

const fetchProgramProgress = async (msalInstance: IPublicClientApplication): Promise<ProgramProgressSummary> =>
  apiGet(`/api/mynxuaggregator/self/program-enrollment/summary`, learnerErrMsg.fetchLearner, msalInstance);

const useProgramProgress = (hasAccount: boolean, msalInstance: IPublicClientApplication) =>
  useQuery({
    queryKey: ["learner-program-progress"],
    queryFn: () => fetchProgramProgress(msalInstance),
    throwOnError: false,
    enabled: hasAccount,
    retry: 3,
    staleTime: 600000,
  });

// Get status of all documents associated with learner profile
const fetchDocumentStatus = async (msalInstance: IPublicClientApplication): Promise<DocumentStatusList> =>
  apiGet("/api/learner-identity-api/self/documents-status", learnerErrMsg.fetchDocumentStatus, msalInstance);

const useDocumentStatus = (hasAccount: boolean, msalInstance: IPublicClientApplication) =>
  useQuery({
    queryKey: ["document-status"],
    queryFn: () => fetchDocumentStatus(msalInstance),
    throwOnError: false,
    enabled: hasAccount,
    retry: 3,
    staleTime: 600000,
  });

// Get aep documents status associated with learner profile
const fetchAepDocumentStatus = async (msalInstance: IPublicClientApplication): Promise<Array<AepDocument>> =>
  apiGet("/api/admission/learners/self/aepdocuments", learnerErrMsg.fetchDocumentStatus, msalInstance);

const useAepDocumentStatus = (hasAccount: boolean, msalInstance: IPublicClientApplication) =>
  useQuery({
    queryKey: ["aep-documents-status"],
    queryFn: () => fetchAepDocumentStatus(msalInstance),
    throwOnError: false,
    enabled: hasAccount,
    retry: 3,
    staleTime: 600000,
  });

// Post the document file to api store
const useFileUploadService = () =>
  useMutation({
    mutationFn: (prop: { payload: { file: File }; fileExtension: string; msalInstance: IPublicClientApplication }) =>
      apiPostFile(
        `/api/learner-files/learners/files/temp/${prop.fileExtension}`,
        prop.payload.file,
        learnerErrMsg.uploadDocument,
        prop.msalInstance,
      ),
  });

// Save the name of the uploaded document to the learner identity
const useDocumentSave = () =>
  useMutation({
    mutationFn: (prop: {
      payload: { uploadedFileName: string; originalFileName: string };
      documentType: DocumentStatusTypes;
      msalInstance: IPublicClientApplication;
    }) =>
      apiPost(
        `/api/learner-identity-api/self/documents/${prop.documentType}`,
        prop.payload,
        learnerErrMsg.uploadDocument,
        prop.msalInstance,
      ),
  });

// Save the name of the uploaded document to the learner identity
const useGovIdDocumentSave = () =>
  useMutation({
    mutationFn: (prop: {
      payload: { uploadedFileName: string; originalFileName: string; officialName: string };
      documentType: DocumentStatusTypes;
      msalInstance: IPublicClientApplication;
    }) =>
      apiPost("/api/learner-identity-api/self/gov-id", prop.payload, learnerErrMsg.uploadDocument, prop.msalInstance),
  });

// Get FERPA privacy settings associated with learner profile
const fetchPrivacySetting = async (msalInstance: IPublicClientApplication): Promise<PrivacySettingResponse> =>
  apiGet("/api/learner-profile/self/privacy-setting", learnerErrMsg.fetchPrivacySetting, msalInstance);

const usePrivacySetting = (hasAccount: boolean, msalInstance: IPublicClientApplication) =>
  useQuery({
    queryKey: ["privacy-setting"],
    queryFn: () => fetchPrivacySetting(msalInstance),
    throwOnError: false,
    enabled: hasAccount,
    retry: 3,
    staleTime: 600000,
  });

const usePrivacySettingUpdate = () =>
  useMutation({
    mutationFn: (prop: { payload: PrivacySettingPayload; msalInstance: IPublicClientApplication }) =>
      apiPut(
        "/api/learner-profile/self/privacy-setting",
        prop.payload,
        learnerErrMsg.fetchPrivacySetting,
        prop.msalInstance,
      ),
  });

const fetchPrivacyAgreementMarkdown = async (
  msalInstance: IPublicClientApplication,
): Promise<{ Content: string; Version: number }> =>
  apiGet(`/api/agreement/ferpa`, learnerErrMsg.fetchPrivacySetting, msalInstance);

const usePrivacyAgreementMarkdown = (hasAccount: boolean, msalInstance: IPublicClientApplication) =>
  useQuery({
    queryKey: ["privacy-agreement-markdown"],
    queryFn: () => fetchPrivacyAgreementMarkdown(msalInstance),
    throwOnError: false,
    enabled: hasAccount,
    retry: 3,
    staleTime: Infinity,
  });

export {
  useLearner,
  useDocumentStatus,
  usePrivacySetting,
  usePrivacySettingUpdate,
  usePrivacyAgreementMarkdown,
  useAepDocumentStatus,
  useFileUploadService,
  useDocumentSave,
  useProgramProgress,
  useGovIdDocumentSave,
};
