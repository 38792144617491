import { useQuery } from "@tanstack/react-query";
import { IPublicClientApplication } from "@azure/msal-browser";

import { PartnerRequest } from "types/partner";

import { apiGet } from "./api-client";

export const partnerErrMsg = {
  partnerDetails:
    "Uh-oh! There was an error on attempting to retrieve the partner details. Please refresh and try again or contact support.",
};

const fetchPartnerDetails = async (
  msalInstance: IPublicClientApplication,
  partnerId: string,
): Promise<PartnerRequest> =>
  apiGet(`/api/partners-api/v1/partners/${partnerId}/public`, partnerErrMsg.partnerDetails, msalInstance);

const usePartnerDetails = (msalInstance: IPublicClientApplication, partnerId?: string) =>
  useQuery({
    queryKey: ["partner-details"],
    queryFn: partnerId ? () => fetchPartnerDetails(msalInstance, partnerId) : () => null,
    throwOnError: false,
    retry: 3,
    enabled: !!partnerId,
  });

export { usePartnerDetails };
